import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import {
  EventBus,
  EventBusConstants,
  POD_TRACKER_DRIVER_PAGINATION,
  POD_TRACKER_PAGINATION,
  Utils,
} from '@/app/infrastructures/misc'
import { PODTrackerPresenter } from '@/app/ui/presenters/PODTrackerPresenter'
import {
  POD,
  PODDetail,
  PODs,
  PODTrackerDriver,
  PODTrackerDrivers,
} from '@/domain/entities/PODTracker'
import { Pagination } from '@/domain/entities/Pagination'
import { OriginRoute } from '@/domain/entities/Route'
import { ExportPOD, UpdatePODHistory } from '@/data/payload/api/PODTrackerRequests'

interface Dropdown {
  value: string
  label: string
}

export interface PODDriver {
  courierName: string
  courierId: number
  phoneNumber: string
  courierType: string
  origin: string
}

export interface PODTrackerState {
  isLoading: boolean
  driverData: PODTrackerDriver[]
  driverPaginationData: Pagination
  podData: POD[]
  podPaginationData: Pagination
  originData: Dropdown[]
  isOriginLoading: boolean
  isExportLoading: boolean
  podDriver: PODDriver
  podDetail: PODDetail
  isSuccessExport: boolean
}

export interface ExportPODForm {
  startDate?: string
  endDate?: string
  courierId?: number
  email?: string
  cmsUserId?: number
  cmsUserName?: string
}

@Module({ namespaced: true, store, name: 'podTracker', dynamic: true })
class PODTrackerController extends VuexModule implements PODTrackerState {
  private presenter: PODTrackerPresenter = container.resolve(
    PODTrackerPresenter
  )
  public isLoading = false
  public driverData: PODTrackerDriver[] = []
  public driverPaginationData = new Pagination(1, POD_TRACKER_DRIVER_PAGINATION)
  public podData: POD[] = []
  public podPaginationData = new Pagination(1, POD_TRACKER_PAGINATION)
  public originData: Dropdown[] = []
  public isOriginLoading = false
  public isExportLoading = false
  public podDriver: PODDriver = {} as PODDriver
  public podDetail: PODDetail = {}
  public isSuccessExport = false
  public constant = {
    driverType: [
      {
        value: '',
        label: 'Semua',
      },
      {
        value: 'KVP',
        label: 'KVP',
      },
      {
        value: 'POS',
        label: 'POS',
      },
    ],
    podStatus: [
      {
        value: '',
        label: 'Semua',
      },
      {
        value: 'INVALID',
        label: 'Fiktif',
      },
      {
        value: 'VALID',
        label: 'Sesuai',
      },
    ],
  }

  @Action({ rawError: true })
  public getDriverList(params: Record<string, string | number | undefined>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getDrivers(formattedParams)
      .then(res => {
        this.setDriverData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Driver Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getOriginList() {
    this.setOriginLoading(true)

    this.presenter
      .getOrigins()
      .then(res => {
        this.setOriginData(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Origin Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setOriginLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPODList(params: Record<string, string | number | undefined>) {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    let PODs
    if (params.courierId) {
      PODs = this.presenter.getPODsByCourier(
        Number(params.courierId),
        formattedParams
      )
    } else {
      PODs = this.presenter.getPODs(formattedParams)
    }

    PODs.then(res => {
      this.setPODData(res)

      if (params.courierId) {
        this.setPODDriver(res)
      }
    })
      .catch(error => {
        Vue.notify({
          title: 'Fetch POD Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPODDetail(packageId: string) {
    this.setLoading(true)
    this.presenter
      .getPODDetail(packageId)
      .then(res => {
        this.setPODDetail(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch POD Detail Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public updatePODHistory(form: {
    packageId: string
    historyId: number
    isValidPod: boolean
    note: string
  }) {
    this.setLoading(true)
    this.presenter
      .updatePODHistory(
        new UpdatePODHistory(
          form.packageId,
          form.historyId,
          form.isValidPod,
          form.note
        )
      )
      .then(() => {
        EventBus.$emit(EventBusConstants.UPDATE_POD_HISTORY_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update POD Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public exportPODReport(form: ExportPODForm) {
    this.setExportLoading(true)

    this.presenter
      .exportPODReport(
        new ExportPOD(
          form.startDate,
          form.endDate,
          form.courierId,
          form.email,
          form.cmsUserId,
          form.cmsUserName
        )
      )
      .then(() => {
        this.setSuccessExport(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Export POD Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setExportLoading(false)
      })
  }

  @Mutation
  private setDriverData(drivers: PODTrackerDrivers) {
    this.driverPaginationData = drivers.pagination as Pagination
    this.driverData = drivers.data as PODTrackerDriver[]
  }

  @Mutation
  private setOriginData(origins: OriginRoute[]) {
    this.originData = origins
      .map(origin => ({
        value: origin.letterCode as string,
        label: origin.letterCode as string,
      }))
      .filter(
        (element, i, val) => val.findIndex(t => t.value === element.value) === i
      )
  }

  @Mutation
  private setPODData(pods: PODs) {
    this.podPaginationData = pods.pagination as Pagination
    this.podData = pods.data as POD[]
  }

  @Mutation
  private setPODDriver(pods: PODs) {
    this.podDriver = {
      courierName: pods.courierName as string,
      courierId: pods.courierId as number,
      phoneNumber: Utils.countryIndonesiaPhoneNumber(
        pods.phoneNumber as string,
        true
      ),
      courierType: pods.courierTypeValid as string,
      origin: pods.origin as string,
    }
  }

  @Mutation
  private setPODDetail(pod: PODDetail) {
    this.podDetail = pod
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setExportLoading(bool: boolean) {
    this.isExportLoading = bool
  }

  @Mutation
  private setOriginLoading(bool: boolean) {
    this.isOriginLoading = bool
  }

  @Mutation
  public addOrigin(origin: { label: string; value: string }) {
    this.originData = [...this.originData, origin]
  }

  @Mutation
  public setSuccessExport(bool: boolean) {
    this.isSuccessExport = bool
  }
}

export default getModule(PODTrackerController)
