import {
  ExportPODInterface,
  ExportPODPayloadInterface,
  UpdatePODHistoryInterface,
} from '@/data/payload/contracts/PODTrackerRequest'

export class UpdatePODHistory implements UpdatePODHistoryInterface {
  public packageId?: string
  public historyId?: number
  public isValidPOD?: boolean
  public note?: string

  constructor(
    packageId?: string,
    historyId?: number,
    isValidPOD?: boolean,
    note?: string
  ) {
    this.packageId = packageId
    this.historyId = historyId
    this.isValidPOD = isValidPOD
    this.note = note
  }

  public toPayload(): string {
    return JSON.stringify({
      package_id: this.packageId,
      history_id: this.historyId,
      is_valid_pod: this.isValidPOD,
      note: this.note,
    })
  }
}

export class ExportPOD implements ExportPODInterface {
  public startDate?: string
  public endDate?: string
  public courierId?: number
  public email?: string
  public cmsUserId?: number
  public cmsUserName?: string

  constructor(
    startDate?: string,
    endDate?: string,
    courierId?: number,
    email?: string,
    cmsUserId?: number,
    cmsUserName?: string
  ) {
    this.startDate = startDate
    this.endDate = endDate
    this.courierId = courierId
    this.cmsUserId = courierId
    this.email = email
    this.cmsUserId = cmsUserId
    this.cmsUserName = cmsUserName
  }

  public toPayload(): ExportPODPayloadInterface {
    return {
      start_date: this.startDate,
      end_date: this.endDate,
      courierId: this.courierId,
      email: this.email,
      cms_user_id: this.cmsUserId,
      cms_user_name: this.cmsUserName,
    }
  }
}
