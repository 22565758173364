

























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/PODTrackerController'
import Button from '@/app/ui/components/Button/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import {
  POD_TRACKER_DRIVER_PAGINATION,
  Utils,
} from '@/app/infrastructures/misc'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { PODTrackerDriver } from '@/domain/entities/PODTracker'
import { Dictionary } from 'vue-router/types/router'

interface Parameter {
  page: number
  perPage: number
  driverType?: string
  origin?: string
  q?: string
  sortBy?: string
}

interface Dropdown {
  label: string
  value: string
}

@Component({
  components: {
    Button,
    LoadingOverlay,
    TextInput,
    DropdownSelect,
    DataTable,
    PaginationNav,
  },
})
export default class CourierPODListPage extends Vue {
  controller = controller

  parameters: Parameter = {
    page: 1,
    perPage: POD_TRACKER_DRIVER_PAGINATION,
  }

  headers = ['No.', 'Nama', 'ID Kurir', 'Nomor HP', 'Jenis Kurir', '3LC', '']

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = this.$route.query as Record<string, never>
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage:
          Utils.alwaysNumber(queries.perPage) || POD_TRACKER_DRIVER_PAGINATION,
        q: queries.q || undefined,
        driverType: queries.driverType || undefined,
        origin: queries.origin || undefined,
        sortBy: queries.sortBy || 'ASC',
      }
    }

    this.fetchDriverList()
    this.fetchOriginList()
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  get driverDataTable(): Array<string | number | PODTrackerDriver>[] {
    return controller.driverData.map(driver => [
      '',
      driver.fullname as string,
      driver.courierId as number,
      Utils.countryIndonesiaPhoneNumber(driver.phoneNumber as string, true),
      driver.announcementCourierType as string,
      driver.origin as string,
      driver as PODTrackerDriver,
    ])
  }

  get params(): Record<string, string | number> {
    return {
      ...this.parameters,
    }
  }

  get selectedDriverType(): Dropdown | null {
    if (!this.parameters.driverType) return null

    return this.driverType.find(
      type => type.value === this.parameters.driverType
    ) as Dropdown
  }

  set selectedDriverType(type: Dropdown | null) {
    this.parameters.driverType = type?.value
  }

  private search = Utils.debounce(() => {
    this.refetchData()
  }, 500)

  get driverType(): Dropdown[] {
    return controller.constant.driverType
  }

  get originOptions(): Dropdown[] {
    return controller.originData
  }

  get selectedOrigin(): Dropdown | null {
    if (!this.parameters.origin) return null

    let selected = this.originOptions.find(
      type => type.value === this.parameters.origin
    ) as Dropdown

    if (selected === undefined) {
      const customOrigin = {
        value: this.parameters.origin,
        label: this.parameters.origin
      }
      controller.addOrigin(customOrigin)

      selected = customOrigin
    }

    return selected
  }

  set selectedOrigin(type: Dropdown | null) {
    this.parameters.origin = type?.value
  }

  get sortByOptions(): Dropdown[] {
    return [
      {
        value: 'ASC',
        label: 'A-Z',
      },
      {
        value: 'DESC',
        label: 'Z-A',
      },
    ]
  }

  get selectedSort(): Dropdown | null {
    if (!this.parameters.sortBy) return null

    return this.sortByOptions.find(
      type => type.value === this.parameters.sortBy
    ) as Dropdown
  }

  set selectedSort(type: Dropdown | null) {
    this.parameters.sortBy = type?.value
  }

  private refetchData() {
    this.fetchDriverList(true)
  }

  private fetchDriverList(resetPage = false): void {
    if (resetPage) this.parameters.page = 1

    this.controller.getDriverList({
      ...this.params,
      q: this.replacePhoneNumber(this.parameters.q || ''),
      courierStatus: 'ACTIVE',
    })
  }

  private fetchOriginList(): void {
    if (this.controller.originData.length === 0) {
      this.controller.getOriginList()
    }
  }

  get isOriginLoading(): boolean {
    return this.controller.isOriginLoading
  }

  private replacePhoneNumber(text: string): string {
    if (/^08([0-9]+)$/.test(text)) {
      return Utils.countryIndonesiaPhoneNumber(text).trim()
    }

    return text
  }

  private addOrigin(newOrigin: string) {
    const origin = {
      value: newOrigin.toUpperCase(),
      label: newOrigin
    }

    controller.addOrigin(origin)

    this.parameters.origin = origin.value
    this.refetchData()
  }
}
